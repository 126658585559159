/* CouponVerification.css */

/* Styling for the main container */
.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-items: center; /* Zarovnání na střed ve směru osy X */
    justify-content: center; /* Zarovnání na střed ve směru osy Y */
    text-align: center; /* Zarovnání textu na střed */
}

/* Styling for headings */
h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

/* Styling for labels */
label {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
    color: #555;
}

/* Styling for input fields */
input[type="text"] {
    width: 300px; /* Šířka vstupního pole 300px */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    margin-bottom: 10px;
}

/* Styling for redeem button */
.redeem-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    margin-right: 10px;
}

.redeem-button:hover {
    background-color: #0056b3;
}

/* Styling for verify button */
.verify-button {
    padding: 10px 20px;
    border: 2px solid #007bff; /* Modrý rámeček */
    border-radius: 4px;
    background-color: #fff; /* Bílé pozadí */
    color: #007bff; /* Modrá barva textu */
    font-size: 16px;
    cursor: pointer;
    margin-right: 10px;
}

.verify-button:hover {
    background-color: #007bff; /* Modrá barva pozadí při najetí myší */
    color: #fff; /* Bílá barva textu při najetí myší */
}

/* Styling for paragraphs */
p {
    margin-top: 10px;
    font-size: 16px;
    color: #333;
}
