/* CouponManagement.css */

/* Styling for buttons */
button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    margin-right: 10px;
}

button:hover {
    background-color: #0056b3;
}

.logout-button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    background-color: #dc3545;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.logout-button:hover {
    background-color: #c82333;
}

.generate-button {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    background-color: #28a745;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    margin-left: 10px;
    margin-bottom: 10px; /* Přidání marginu od spodu */
}

.generate-button:hover {
    background-color: #218838;
}

.delete-button {
    padding: 6px 10px; /* Zmenšení paddingu pro tlačítko */
    border: none;
    border-radius: 4px;
    background-color: #dc3545; /* Červená barva pro smazání */
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    margin-right: 10px; /* Odsazení od sousedních prvků */
    margin-bottom: 15px; /* Přidání marginu od spodu */
}

.delete-button:hover {
    background-color: #c82333; /* Změna barvy při najetí myší */
}

/* Styling for coupon table */
.coupon-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px; /* Odsazení od předchozího prvku */
}

.coupon-table th,
.coupon-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.coupon-table th {
    background-color: #f2f2f2;
}

.coupon-table tr:hover {
    background-color: #f2f2f2;
}
/* Styling for date input */
.coupon-form input[type="date"] {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 150px;
    box-sizing: border-box;
    margin-bottom: 10px; /* Odsazení od spodních prvků */
}

.coupon-form label {
    display: block; /* Přidává blokový zobrazení pro popisky */
    margin-bottom: 5px; /* Odsazení mezi popiskem a vstupním polem */
}