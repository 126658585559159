/* Styling for home container */
.home-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 40px;
    border-radius: 8px;
    background-color: #f0f0f0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

/* Styling for headings */
h1 {
    font-size: 36px;
    margin-bottom: 30px;
    color: #333;
}

/* Styling for links */
.link {
    display: inline-block;
    margin: 10px 20px;
    padding: 12px 24px;
    border-radius: 8px;
    background-color: #007bff;
    color: #fff;
    font-size: 18px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.link:hover {
    background-color: #0056b3;
}
